import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import { ModalPlugin, ToastPlugin, TooltipPlugin, BButton } from 'bootstrap-vue';
import VueLogger from 'vuejs-logger';
import VueNativeSock from 'vue-native-websocket';
import VueMeta from 'vue-meta';
import router from './router';
import store from './store/index';
import i18n from './i18n';
import App from './App';

const isProduction = process.env.NODE_ENV === 'production';
const { VUE_APP_SOCKET_ENDPOINT } = process.env;

const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};
Vue.use(ModalPlugin);
Vue.use(ToastPlugin);
Vue.use(VueLogger, options);
Vue.use(VueMeta);
Vue.use(TooltipPlugin);
Vue.use(VueNativeSock, `${VUE_APP_SOCKET_ENDPOINT}`, { store, connectManually: true });
Vue.component('b-button', BButton);

Vue.use(VueAxios, axios);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
