const axios = require('axios').default;

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
axios.defaults.headers.patch['Content-Type'] = 'application/json; charset=utf-8';

const { VUE_APP_LIVE_ENDPOINT, VUE_APP_STAGING_ENDPOINT, VUE_APP_LIVE_NEW_ENDPOINT } = process.env;

function getAuthHeader(context, jwt = undefined, upload) {
  const config = (upload) ? { headers: { 'Content-Type': 'multipart/form-data' } } : { headers: { } };
  if (context && context.rootState.whiteLabel && context.rootState.whiteLabel.agencyCode) {
    config.headers.agency = context.rootState.whiteLabel.agencyCode;
  }
  if (context && context.rootState.agencyUsers.marketerId) {
    config.headers.agency = context.rootState.agencyUsers.marketerId;
  }
  if (jwt || (context && context.rootState.users && context.rootState.users.userToken)) {
    config.headers.Authorization = `bearer ${jwt || context.rootState.users.userToken}`;
  }
  if (context && context.rootState.memberUsers.userMemberSid) {
    config.headers.memberSid = context.rootState.memberUsers.userMemberSid;
  }
  if (context && context.rootState.agencyUsers.userAgencySid) {
    config.headers.agencySid = context.rootState.agencyUsers.userAgencySid;
  }
  return config;
}

function getAuthHeaderText() {
  const config = { headers: { 'content-type': 'text/plain; charset=utf-8' } };
  return config;
}

function getServerDomain(endpoint, context, url) {
  // eslint-disable-next-line no-nested-ternary
  const originDomain = (context && context.rootState.isStaging)
    ? VUE_APP_STAGING_ENDPOINT
    : (window.location.href.indexOf('flyingisr') > -1)
      ? VUE_APP_LIVE_NEW_ENDPOINT
      : VUE_APP_LIVE_ENDPOINT;
  // eslint-disable-next-line no-nested-ternary
  return (endpoint === 'DOMESTIC')
    ? `${originDomain}/domestic/api${url}`
    : (endpoint === 'CAMINGO') ? `${originDomain}/camingo/api${url}`
      : `${originDomain}/api${url}`;
}

function get(endpoint, url, context) {
  const headers = getAuthHeader(context);
  return axios.get(getServerDomain(endpoint, context, url), headers);
}

function post(endpoint, url, body, context, jwt, formData) {
  const headers = getAuthHeader(context, jwt, formData);
  return axios.post(getServerDomain(endpoint, context, url), body, headers);
}

function postWithText(endpoint, url, body, context, jwt) {
  const headers = getAuthHeaderText(context, jwt);
  return axios.post(getServerDomain(endpoint, context, url), body, headers);
}

function put(endpoint, url, body, context, jwt) {
  const headers = getAuthHeader(context, jwt, true);
  return axios.put(getServerDomain(endpoint, context, url), body, headers);
}

function patch(endpoint, url, body, context, jwt) {
  const headers = getAuthHeader(context, jwt);
  return axios.patch(getServerDomain(endpoint, context, url), body, headers);
}

function deleteApi(endpoint, url, body, context, jwt) {
  const headers = getAuthHeader(context, jwt);
  return axios.delete(getServerDomain(endpoint, context, url), headers);
}
const utf8ToB64 = (str) => (window.btoa(unescape(encodeURIComponent(str))));
const b64ToUtf8 = (str) => (decodeURIComponent(escape(window.atob(str))));

export {
  get, post, patch, deleteApi, put, postWithText, utf8ToB64, b64ToUtf8,
};
