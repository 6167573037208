import { get, post } from '@/utils/api';

const { VUE_APP_SOCKET_ENDPOINT, VUE_APP_SOCKET_STAGING } = process.env;
const filterArray = (arr, item, cond) => arr.filter((element) => (element[item].toString().toLowerCase() === cond.toString().toLowerCase()));

export default {
  state: () => ({
    socket: null,
    isConnected: false,
    message: '',
    reconnectError: null,
    tableDataLoading: false,

    allPendingBookingInfo: [],
    newPriceInfo: null,
    finalizeInfo: null,
    atlantisFinalizeInfo: null,
    externalBookingInfo: null,

    filterPnr: '',
    filterAgent: '',
    filterTag: '',
    filterClerk: '',
    filteredPendingBookingInfo: [],
  }),
  getters: {
    IS_PENDING_SOCKET_CONNECTED: (state) => state.isConnected,
    GET_MESSAGE: (state) => state.message,
    GET_TABLE_LOADING_STATE: (state) => state.tableDataLoading,

    GET_PENDING_BOOKING_INFO: (state) => state.filteredPendingBookingInfo,
    GET_NEW_PRICE_INFO: (state) => state.newPriceInfo,
    GET_FINALIZE_INFO: (state) => state.finalizeInfo,
    GET_ATLANTIS_FINALIZE_INFO: (state) => state.atlantisFinalizeInfo,
    GET_EXTERNAL_BOOKING_INFO: (state) => state.externalBookingInfo,
  },
  mutations: {
    SOCKET_ONOPEN(state) {
      const socket = state.socket || {};
      state.socket = socket;
      state.isConnected = true;
    },
    SOCKET_ONCLOSE(state) {
      state.isConnected = false;
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event);
      state.tableDataLoading = false;
    },
    SOCKET_ONMESSAGE(state, event) {
      const message = event.data;
      state.message = message;
      if (message) {
        const data = JSON.parse(message);
        if (Array.isArray(data)) {
          state.allPendingBookingInfo = [];
          state.allPendingBookingInfo.push(...data);
        } else {
          state.allPendingBookingInfo.push(data);
        }

        state.filteredPendingBookingInfo = [...state.allPendingBookingInfo];
        if (state.filterPnr) {
          state.filteredPendingBookingInfo = filterArray(state.filteredPendingBookingInfo, 'pnr', state.filterPnr);
        }
        if (state.filterAgent) {
          state.filteredPendingBookingInfo = filterArray(state.filteredPendingBookingInfo, 'odyAgentCode', state.filterPnr);
        }
        state.tableDataLoading = false;
      }
    },
    SOCKET_RECONNECT(state, count) {
      console.info(state, count);
    },
    SOCKET_RECONNECT_ERROR(state) {
      const reconnectError = state.reconnectError || {};
      state.reconnectError = reconnectError;
    },

    SET_TABLE_LOADING_STATE(state, payload) {
      state.tableDataLoading = payload;
    },

    FILTER_PENDING_BOOKING_INFO: (state, payload) => {
      const { pnr, agent, tag, clerk } = payload;
      state.filterPnr = pnr;
      state.filterAgent = agent;
      state.filterTag = tag;
      state.filterClerk = clerk;

      const allInfo = state.allPendingBookingInfo;
      let filteredInfo = [];

      if (pnr) {
        filteredInfo = filterArray(allInfo, 'pnr', pnr);
      } else {
        filteredInfo = [...allInfo];
      }
      if (agent) {
        filteredInfo = filterArray(filteredInfo, 'odyAgentCode', agent);
      } else {
        filteredInfo = [...filteredInfo];
      }
      if (tag) {
        filteredInfo = filterArray(filteredInfo, 'operatorTag', tag);
      } else {
        filteredInfo = [...filteredInfo];
      }
      if (clerk) {
        filteredInfo = filterArray(filteredInfo, 'clerkName', clerk);
      } else {
        filteredInfo = [...filteredInfo];
      }
      state.filteredPendingBookingInfo = filteredInfo;
    },
    SET_PENDING_BOOKING_INFO: (state, payload) => {
      state.allPendingBookingInfo = payload;
    },
    SET_TAG_TO_INFO: (state, payload) => {
      const { bookingTransId, tag } = payload;
      state.allPendingBookingInfo.find((pending) => (pending.bookTransaction.guid === bookingTransId)).operatorTag = tag;
    },
    SET_NEW_PRICE_INFO: (state, payload) => {
      state.newPriceInfo = payload;
    },
    SET_FINALIZE_INFO: (state, payload) => {
      state.finalizeInfo = payload;
    },
    SET_ATLANTIS_FINALIZE_INFO: (state, payload) => {
      state.atlantisFinalizeInfo = payload;
    },
    SET_EXTERNAL_BOOKING_INFO: (state, payload) => {
      const { bookingTransId, body } = payload;
      const bookingData = state.allPendingBookingInfo.find((pending) => (pending.bookTransaction.guid === bookingTransId));
      state.externalBookingInfo = { bookingTransId, body, bookingData };
    },
  },
  actions: {
    SEND_MESSAGE_VIA_SOCKET: (context, message) => {
      const { socket } = context.state;
      if (socket) { socket.send(message); }
    },
    SOCKET_CONNECT: (context, payload) => {
      const vm = payload;
      const { userAgencySid } = context.rootState.agencyUsers;
      const { agencyCode } = context.rootState.whiteLabel;
      const { isStaging } = context.rootState;
      const base = isStaging ? VUE_APP_SOCKET_STAGING : VUE_APP_SOCKET_ENDPOINT;

      context.commit('SET_TABLE_LOADING_STATE', true);
      vm.$connect(`${base}/camingo/api/operation/newPendingExtBook/?agency=${agencyCode}&agencySid=${userAgencySid}`);
    },
    SOCKET_DIS_CONNECT: (context, payload) => {
      const vm = payload;
      vm.$disconnect();
      const { socket } = context.state;
      socket.close();
    },

    POST_FINALIZE_PENDING_EXTERNAL_BOOK: async (context, payload) => {
      context.commit('SET_LOADING_STATE', true);
      const { bookingTransId, body } = payload;
      try {
        const response = await post('CAMINGO', `/operation/finalizePendingExtBook/${bookingTransId}`, body, context);
        context.commit('SET_FINALIZE_INFO', response.data);
        context.commit('SET_LOADING_STATE', false);
        return response;
      } catch (e) {
        context.commit('SET_LOADING_STATE', false);
        return e;
      }
    },
    POST_ATLANTIS_HOTEL_EXTERNAL_BOOK: async (context, payload) => {
      const { bookingTransId, body } = payload;
      try {
        const response = await post('CAMINGO', `/operation/confirmAtlantisBook/${bookingTransId}`, body, context);
        context.commit('SET_ATLANTIS_FINALIZE_INFO', response.data);
        return response;
      } catch (e) {
        return e;
      }
    },
    FETCH_NEW_PRICE_INFO: async (context, payload) => {
      context.commit('SET_LOADING_STATE', true);
      const { bookingTransId } = payload;
      try {
        const response = await get('CAMINGO', `/operation/refreshRoomRates/${bookingTransId}`, context);
        context.commit('SET_NEW_PRICE_INFO', response.data);
        context.commit('SET_LOADING_STATE', false);
        return response;
      } catch (e) {
        context.commit('SET_LOADING_STATE', false);
        return e;
      }
    },
    SET_TAG_OPTION: async (context, payload) => {
      const { bookingTransId, tag } = payload;
      try {
        await post('CAMINGO', `/operation/tagBook/${bookingTransId}?tag=${tag}`, {}, context);
        context.commit('SET_TAG_TO_INFO', payload);
      } catch (error) {
        console.log(error);
      }
    },
    FETCH_ALL_BOOKING_INFO: async (context) => {
      context.commit('SET_LOADING_STATE', true);
      try {
        const response = await get('CAMINGO', '/operation/pendingExtBook', context);
        context.commit('SET_PENDING_BOOKING_INFO', response.data);
        context.commit('SET_LOADING_STATE', false);
        return response;
      } catch (e) {
        context.commit('SET_LOADING_STATE', false);
        return e;
      }
    },
  },
};
