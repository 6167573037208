function toBase64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

function fromBase64(b64) {
  return decodeURIComponent(escape(window.atob(b64)));
}

export {
  toBase64, fromBase64,
};
